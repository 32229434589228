import React, { useCallback, useContext, useState, useEffect, Fragment, useMemo, useRef } from "react";
import Form from "react-bootstrap/Form";
import { v4 as uuidv4 } from 'uuid';
import { QuestionOption, QuestionProps } from "./Props";

import { languageContent, safeParse, filterByQuery } from "../Util";
import InterfaceContext, { PublicConfigurationContext, SupportedLanguage } from "../Context";
import { Dropdown } from "react-bootstrap";
import { Combobox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon, XMarkIcon } from "@heroicons/react/24/solid";
import { useToast } from "@aidkitorg/component-library";
import { useLocalizedStrings } from "../Localization";
import { useDebouncedCallback } from "../Hooks/Debounce";
import { useModularMarkdown } from "../Hooks/ModularMarkdown";
import { usePost } from "../API";
import { fisherYatesShuffle, hash } from "../utils/orderRandomizer";
import type { ServerSide, TrackedObjectInfo } from '@aidkitorg/types/lib/survey';
import { MutableTextWrapper } from "../MutableText";
import { SupportedLanguages } from "@aidkitorg/i18n/lib";
import { PublicConfig } from "@aidkitorg/types/lib/config";

type ChoiceType = {
  value: string,
  label: {
    [key in (typeof SupportedLanguages)[number]]: string
  } & { _trackedObjInfo?: TrackedObjectInfo, }
}

function ConfirmQuestion(props: QuestionProps) {
  const context = useContext(InterfaceContext);
  const metadata = safeParse(props["Metadata"] || '{}');
  const L = useLocalizedStrings();

  const name = props["Target Field"]!;
  const label = metadata?.label || '';
  const labelTranslations = metadata?.label_translations as Record<SupportedLanguage, string>;

  const content = props[languageContent(context.lang)]?.replace("\\(", "(").replace("\\[", "[") || '';

  const formatted = (props["Additional Options"] || []).indexOf('Formatted') !== -1;

  // Make sure all asterisks (**) for boldness are snug up next to the next they need to be next to.
  // Also, make sure all underscores (_) for italics are snug up next to the next they need to be next to.
  const marked = useModularMarkdown({
    content,
    info: props.info,
    replacer: (str) => str.replace(' **\n', '**\n').replace('^** ', '**')
  });

  function isChecked() {
    return (props.info[props["Target Field"]!] || "") === "yes";
  }

  function setChecked(checked: boolean) {
    props.setInfoKey(props["Target Field"]!, checked ? "yes" : "", true, false);
  }

  if ((props["Additional Options"] || []).indexOf("Hidden") !== -1) {
    return <span></span>
  }

  return (
    <fieldset>
      <legend>
        {formatted ?
          marked :
          (labelTranslations && labelTranslations[context.lang] ? labelTranslations[context.lang] : label)
        }
      </legend>
      <Form.Group>
        <Form.Check
          checked={isChecked()}
          onChange={(e: any) => setChecked(e.target.checked)}
          type="checkbox"
          name={name}
          id={props['Target Field']}
          label={formatted ? L.questions.choice.yes : props[languageContent(context.lang)]}
        />
      </Form.Group>
    </fieldset>
  );
}

function getOptionsFromProps(props: QuestionProps) {
  // if SelectFromData, get options from source field, else use "Options (if relevant)"
  const metadata = safeParse(props["Metadata"] || '{}');
  const sourceValue = safeParse(props.info[metadata.source_field] || '""');
  if (metadata.source_field && Array.isArray(sourceValue)) {
    return sourceValue.map((i: string) => ({ 'Name': i, "English Text": i } as QuestionOption));
  } else {
    return props["Options (if relevant)"] || []
  }
}

function SingleSelectQuestion(props: QuestionProps) {
  const context = useContext(InterfaceContext);
  const L = useLocalizedStrings();
  const metadata = safeParse(props["Metadata"] || '{}');
  const targetField = props["Target Field"]!;
  let options = getOptionsFromProps(props);
  const propsInfo = props.info;
  const setInfoKey = useCallback(props.setInfoKey, [propsInfo]);
  const config: PublicConfig = useContext(PublicConfigurationContext);

  const name = props["Target Field"] || "";

  const horizontal = (props["Additional Options"] || []).indexOf("Horizontal") !== -1;
  const no_labels = (props["Additional Options"] || []).indexOf("No Labels") !== -1;

  const regex = /\$[a-zA-Z\\_][a-zA-Z\\_0-9]+/g;

  function replacer(match: string) {
    const variable = match.slice(1).replace(/\\_/g, '_');

    // console.log("Found match:", variable, variable === "uid");

    if (variable === "uid") return props.uid;
    if (props.info[variable] !== undefined && props.info[variable] !== null) return props.info[variable];
    return match;
  }

  const marked = useModularMarkdown({
    content: props[languageContent(context.lang)] || '',
    info: props.info,
    replacer: str => str.replace(regex, replacer)
  });

  const mainFormattedContent = config.experimental?.inlineEditing
    ? <MutableTextWrapper
      content={{ [context.lang]: (props[languageContent(context.lang)] || '').replace(regex, replacer) } as any}
      trackedObjInfo={props._trackedObjInfo}
      info={{ ...props.info, uid: props.uid || 'No UID' }}
    />
    : marked;

  let seed: string | null = null;
  if (metadata?.component?.randomizeOrder && targetField) {
    seed = localStorage.getItem('ChoiceRandomizerSeed' + (props.uid || ''));
    if (seed === null) {
      seed = Math.random() + (props.uid || '');
      localStorage.setItem('ChoiceRandomizerSeed' + (props.uid || ''), seed);
    }
    options = fisherYatesShuffle(options, hash(0, seed), targetField);
  }

  function updateOtherValue(otherKey: string, otherValue: string) {
    props.setInfoKey(props["Target Field"] + '_' + otherKey, otherValue, otherValue.length > 0, false);
  }

  function updateValue(newValue: string): void {
    const option = options.find((o: QuestionOption) => o.Name === newValue);

    const disqualified = option && option["Terminates Application"];

    if (propsInfo[targetField] !== newValue) {
      setInfoKey(targetField, newValue, newValue.length > 0, disqualified || false);
    }

    for (const opt of options) {
      if (opt.Name === newValue) continue;
      if ((opt.Name === 'other' || opt["Other Field"]) &&
        props.info[props["Target Field"] + '_' + opt.Name]) {
        updateOtherValue(opt.Name, "");
      }
    }
  }

  async function confirmDelete() {
    const yOrN = window.confirm(L.questions.choice.are_you_sure);
    if (yOrN && yOrN === true) {
      updateValue("");
    }
  }

  let formatted = false;
  if ((props["Additional Options"] || []).indexOf("Formatted") !== -1) {
    formatted = true;
  }

  if ((props["Additional Options"] || []).indexOf("Hidden") !== -1) {
    return <span></span>
  }

  const content = horizontal ?
    (formatted ? mainFormattedContent : props[languageContent(context.lang)]) :
    (formatted ? mainFormattedContent : <b>{props[languageContent(context.lang)]}</b>);

  function ClearAnswerDropdown(props: { targetField: string, confirmDelete: () => void }) {
    return (
      <Dropdown className="applicant-led-hidden" style={{ display: 'inline' }}>
        <Dropdown.Toggle variant="link">...</Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item key={`clear-answer-for-${targetField}`}
            onClick={() => confirmDelete()}>
            {L.questions.choice.clear_answer}
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  }

  const questionID = useMemo(() => uuidv4(), []);

  const choiceToTrackedObjInfoMap: Record<string, TrackedObjectInfo | undefined> = {};
  (metadata?.component?.choices as Array<ChoiceType> | undefined)?.forEach((choice) => {
    choiceToTrackedObjInfoMap[choice.value] = choice.label._trackedObjInfo;
  });

  return (
    <div className="w-full">
      <fieldset>
        <div className={horizontal ? 'hidden' : ''}>
          {!horizontal && <>
            <div id={questionID}>{content}</div>
            <ClearAnswerDropdown targetField={targetField} confirmDelete={confirmDelete} />
          </>}
        </div>
        <div className={horizontal ? "flex flex-col sm:flex-row w-full" : ""}>
          {horizontal && <div className="flex flex-initial items-start w-full mb-3 sm:w-1/4 font-bold sm:font-normal">
            <div className={"sm:-mb-3 " + (horizontal ? "text-sm" : "")} >
              {content}
              <ClearAnswerDropdown targetField={targetField} confirmDelete={confirmDelete} />
            </div>
          </div>}
          {options.map((o) => {
            const fields: any[] = [];
            // If options generated from a source field show Name as label
            const label = (o as any)[languageContent(context.lang).replace('Content','Text')] || o["Name"];
            
            fields.push(<div className={horizontal ? "flex-1 flex flex-col items-start sm:items-center justify-start" : ""} key={o.Name}>
              {horizontal && <div className={"block text-center " + (no_labels ? "none hidden" : "")}>
                {label}
              </div>}
              <div className={"block " + (horizontal ? "center" : "")}>
                <Form.Check
                  type="radio"
                  className=""
                  disabled={config.experimental?.inlineEditing && context.inlineEdit.inlineEditing}
                  onChange={(e: any) => props.info[name] !== o["Name"] && updateValue(o.Name)}
                  name={name}
                  key={o["Name"]}
                  value={o["Name"]}
                  checked={props.info[name] === o["Name"]}
                  id={props['Target Field'] + '_' + o["Name"]}
                  label={<span className={`${context.textAlign === 'right' ? 'mr-4' : 'pl-0'}`}>
                    {config.experimental?.inlineEditing
                      ? <MutableTextWrapper
                        content={{ [context.lang]: label } as any}
                        trackedObjInfo={choiceToTrackedObjInfoMap[o["Name"]]}
                      />
                      : label
                    }
                  </span>}
                  aria-describedby={questionID}
                />
              </div>
            </div>);

            if ((o["Name"] === "other" || o["Other Field"]) && props.info[name] === o["Name"]) {
              fields.push(
                <Form.Group key={name + "_" + o["Name"] + '_group'}>
                  <Form.Control type="text"
                    defaultValue={props.info[props['Target Field'] + '_' + o.Name]}
                    onChange={(e: any) => updateOtherValue(o.Name, e.target.value)}
                    name={name + '_' + o.Name}
                    key={name + '_' + o.Name}
                    aria-describedby={questionID}
                  />
                </Form.Group>);
            }

            return fields;
          })}
        </div>
      </fieldset>
    </div>
  );
}

function MultipleSelectQuestion(props: QuestionProps) {
  const L = useLocalizedStrings();
  const context = useContext(InterfaceContext);
  const metadata = safeParse(props["Metadata"] || '{}');
  const targetField = props["Target Field"]!;
  const fieldValue = props.info[props["Target Field"] || ''];

  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const hasSelectAll = props["Options (if relevant)"]?.some((o: any) => o['Select All'] === true);
  let options = getOptionsFromProps(props);

  const regex = /\$[a-zA-Z\\_][a-zA-Z\\_0-9]+/g;

  function replacer(match: string){
    const variable = match.slice(1).replace(/\\_/g,'_');

    // console.log("Found match:", variable, variable === "uid");

    if(variable === "uid") return props.uid;
    if(props.info[variable] !== undefined && props.info[variable] !== null) return props.info[variable];
    return match;
  }

  const marked = useModularMarkdown({
    content: props[languageContent(context.lang)] || '',
    info: props.info,
    replacer: str => str.replace(regex, replacer)
  });

  let seed: string | null = null;
  if (metadata?.component?.randomizeOrder && targetField) {
    seed = localStorage.getItem('ChoiceRandomizerSeed' + (props.uid || ''));
    if (seed === null) {
      seed = Math.random() + (props.uid || '');
      localStorage.setItem('ChoiceRandomizerSeed' + (props.uid || ''), seed);
    }
    options = fisherYatesShuffle(options, hash(0, seed), targetField);
  }

  useEffect(() => {
    // Determine if all options are selected and thus whether 'select all' should be checked
    // Ignore if none of the options is a 'select all' option
    if (hasSelectAll) {
      const numOptions = props["Options (if relevant)"]?.reduce((total, option) => {
        return option['Exclusive Option'] !== true && option['Select All'] !== true
          ? total + 1
          : total;
      }, 0);
      const numOptionsSelected = fieldValue?.split(',').length || 0;

      if (numOptions === numOptionsSelected) {
        if (!selectAllChecked) {
          setSelectAllChecked(true);
        }
      } else {
        if (selectAllChecked) {
          setSelectAllChecked(false);
        }
      }
    }
  }, [hasSelectAll, fieldValue]);

  function setChecked(option: QuestionOption, checked: boolean) {
    if (option['Exclusive Option'] === true) {
      if (!checked) {
        props.setInfoKey(props["Target Field"]!, '', false, false);
      } else {
        props.setInfoKey(props["Target Field"]!, option.Name, true, false);
      }
      return;
    }

    // 'Select All' option is more like a button for setting other options, not a true option
    if (option["Select All"] === true) {
      if (!checked) {
        props.setInfoKey(props["Target Field"] as string, "", false, false);
      } else {
        // If selecting all would exceed the max number of allowed selections, show an alert instead
        if ((options).length - 1 > metadata.max_allowed_selections) {
          alert(L.questions.choice.already_selected_max);
          return;
        }
        const checkedOptions = [];
        for (const option of props['Options (if relevant)']!) {
          if (option['Exclusive Option'] !== true && option['Select All'] !== true) {
            checkedOptions.push(option.Name);
          }
        }

        props.setInfoKey(props["Target Field"] as string, checkedOptions.join(","), true, false);
      }
      return;
    }

    const kind = option.Name;
    let old = (props.info[props["Target Field"]!] || "")
      .split(",")
      .filter((k: string) => {
        if (k.length === 0) return false;
        for (const o of options) {
          if (o.Name === k && o["Exclusive Option"] === true) return false;
        }
        return true;
      });
    if (checked) {
      if (old.indexOf(kind) === -1) {
        if (old.length >= metadata.max_allowed_selections) {
          // Prevent selecting more than the max number of options
          alert(L.questions.choice.already_selected_max);
          return;
        }
        old.push(kind);
        const next = old.join(",");
        props.setInfoKey(props["Target Field"]!, next, next.length > 0, false);
      }
    } else {
      // Not checked, remove from info
      if (old.indexOf(kind) >= 0) {
        old = old.filter((k: string) => k !== kind);
        const next = old.join(",");
        props.setInfoKey(props["Target Field"]!, next, next.length > 0, false);

        if(kind === 'other' || option["Other Field"]) {
          props.setInfoKey(props["Target Field"]! + '_' + kind, "", true, false);
        }
      }
    }
  }

  function isChecked(o: QuestionOption): boolean {
    if (o['Select All']) {
      return selectAllChecked;
    }
    return (props.info[props["Target Field"]!] || "")
      .split(",")
      .indexOf(o["Name"]) !== -1;
  }

  function updateOtherValue(o: QuestionOption, value: string) {
    props.setInfoKey(props["Target Field"] + '_' + o.Name, value, value.length > 0, false);
  }

  const name = props["Target Field"]!;

  function generateCheckbox(o: QuestionOption): any {
    const fields: any[] = [];

    if (metadata.filter && props.info[metadata.filter] && !props.info[metadata.filter].includes(o['Name'])) {
      return fields;
    }

    fields.push(<Form.Check
      checked={isChecked(o)}
      onChange={(e: any) => setChecked(o, e.target.checked)}
      type="checkbox"
      name={name}
      id={props['Target Field'] + '_' + o["Name"]}
      key={o["Name"]}
      label={ 
        <span className={`${context.textAlign === 'right' ? 'mr-4' : ''}`}>
          {(o as any)[languageContent(context.lang).replace('Content', 'Text')] || o.Name}
        </span>
      } />
    );

    if((o["Name"] === "other" || o["Other Field"]) && isChecked(o)) {
      fields.push(
        <Form.Group key={name + "_" + o["Name"] + '_group'}>
          <Form.Control type="text"
            defaultValue={props.info[props['Target Field'] + '_' + o.Name]}
            onChange={(e: any) => updateOtherValue(o, e.target.value)}
            name={name + '_' + o.Name}
            key={name + '_' + o.Name}
          />
        </Form.Group>);
    }

    return fields;
  }

  let formatted = false;
  if ((props["Additional Options"] || []).indexOf("Formatted") !== -1) {
    formatted = true;
  }

  return (
    <Form.Group>
      <fieldset>
        <legend>
          {formatted ? marked : 
            <b>{props[languageContent(context.lang)]}</b>}
        </legend>
        {options?.map((o) => {
          return generateCheckbox(o)
        })}
      </fieldset>
    </Form.Group>
  );
}

export type Option = {
  id: string;
  text: string;
};

function SearchSelectQuestion(props: QuestionProps) {
  const context = useContext(InterfaceContext);
  const L = useLocalizedStrings();
  const { toast } = useToast();
  const targetField = props["Target Field"]!;

  const [query, setQuery] = useState("");

  const [allOptions, setAllOptions] = useState<Option[]>([]);
  const [displayedOptions, setDisplayedOptions] = useState<Option[]>([]);
  const [selectedOption, setSelectedOption] = useState<Option | null>(null);

  const { search_type = {}, max_options } = JSON.parse(props['Metadata'] || '{}');
  const { optionsFileUrl = {} } = search_type;

  // Fetch options on mount and when language changes
  useEffect(() => {
    if (!optionsFileUrl) return;

    const fetchData = async () => {
      try {
        // if no options file is specified for the user's language, use the English one
        const url = optionsFileUrl[context.lang] || optionsFileUrl['en'];
        const res = await fetch(url, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        });
        const options: Option[] = await res.json();

        setAllOptions(options);
        const optionId = props.info[targetField];
        const option = options?.find(o => o.id === optionId);
        setSelectedOption(option || null);
      } catch (err) {
        console.log(err);
        toast({
          description: L.questions.search_select.options_error,
          variant: 'error'
        });
      }
    };

    fetchData();
  }, [context.lang]);

  // Update displayed options when query changes
  useEffect(() => {
    const queryWords = query
      .trim()
      .toLowerCase()
      .split(" ")
      .filter(word => word);

    setDisplayedOptions(
      queryWords.length === 0
        ? allOptions // Show all options if no query
        : filterByQuery(queryWords, allOptions, max_options, selectedOption?.id || '')
    );
  }, [query, selectedOption, allOptions]);

  const formattedContent = useModularMarkdown({
    content: props[languageContent(context.lang)] || '',
    info: props.info
  });

  const content = (props["Additional Options"] || []).indexOf("Formatted") !== -1
    ? formattedContent
    : <b>{props[languageContent(context.lang)]}</b>;

  if ((props['Additional Options'] || []).indexOf('Hidden') !== -1) {
    return <span></span>;
  }

  return (
    <fieldset>
      <legend>
        {content}
      </legend>

      {/* Search Input */}
      <Combobox
        value={selectedOption}
        onChange={(option) => {
          props.setInfoKey(targetField, option?.id || '', !!option, false);
          setSelectedOption(option || null)
        }}
        nullable
      >
        <div className="relative">
          <Combobox.Input
            className={`w-full block border-solid py-2 pl-2.5 pr-[3.25rem] rounded-md border-2 focus:ring-gray-400 focus:border-gray-400 truncate ${
              props.info[targetField]
                ? 'border-green-400 ring-green-400'
                : props["Additional Options"]?.includes('Optional')
                  ? 'border-gray-200 ring-gray-200'
                  : 'border-red-200 ring-red-400'
            }`}
            displayValue={(option: Option | null) => option?.text || query}
            onChange={(e: any) => setQuery(e.target.value)}
          />

          {/* Clear Button */}
          {selectedOption?.id && (
            <button
              type="button"
              className="absolute inset-y-0 right-7 flex items-center"
              onClick={() => {
                setSelectedOption(null);
                setQuery('');
                props.setInfoKey(targetField, '', false, false);
              }}
            >
              <XMarkIcon className="h-5 w-5 text-gray-400 hover:text-gray-500 transition" aria-hidden="true" />
            </button>
          )}
  
          {/* Dropdown Button */} 
          <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-1.5">
            <ChevronUpDownIcon className="h-5 w-5 text-gray-400 hover:text-gray-500 transition" aria-hidden="true" />
          </Combobox.Button>

          {/* Options List */}
          <Transition
            as={Fragment}
            leave="transition ease-in duration-50"
            afterLeave={() => setQuery('')}
          >
            <Combobox.Options
              className="absolute mt-1 max-h-60 w-full overflow-auto bg-white border border-gray-200 rounded-md py-1 text-base shadow-lg focus:outline-none z-50"
            >
              {displayedOptions.length === 0 ? (
                <div className="select-none px-4 py-2">
                  {L.dashboard.no_results}
                </div>
              ) : (
                displayedOptions.map((option: Option) => (
                  <Combobox.Option
                    key={option.id}
                    className={({ active }) =>
                      `relative cursor-pointer select-none py-2 pl-10 pr-4 ${
                        active ? 'bg-indigo-600 text-white' : 'text-gray-900'
                      }`
                    }
                    value={option}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={`block transition-[max-height] duration-300 ease-in-out ${
                            selected ? 'font-medium' : 'font-normal'
                          } ${
                            active
                              ? 'max-h-48 overflow-visible whitespace-normal text-clip'
                              : 'max-h-6 overflow-hidden text-ellipsis whitespace-nowrap'
                          }`}
                        > 
                          {option.text}
                        </span>
                        {selected && (
                          <span
                            className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                              active ? 'text-white' : 'text-indigo-600'
                            }`}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        )}
                      </>
                    )}
                  </Combobox.Option>
                ))
              )}
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox>
    </fieldset>
  );
}

function SearchSelectLargeDictionaryQuestion(props: QuestionProps) {
  type LgDictOption = Record<string, any> | 'not_found';
  const context = useContext(InterfaceContext);
  const searchLargeDictionary = usePost('/program/search_large_dictionary');

  const L = useLocalizedStrings();
  const { toast } = useToast();
  const targetField = props["Target Field"]!;
  const setInfoKey = useCallback(props.setInfoKey, [props.info]);

  const [query, setQuery] = useState('');
  const [isSearching, setSearching] = useState(false);
  const debouncedSetQuery = useDebouncedCallback((query: string) => setQuery(query));

  const metadata = safeParse(props['Metadata'] || '{}');
  const { max_options, search_type, search_type: { allowNotFound, columns }} = metadata;

  const [filteredOptions, setFilteredOptions] = useState<Record<string, any>[]>([]);
  const [selectedOption, setSelectedOption] = useState<LgDictOption | {}>(
    props.info[targetField] === 'not_found'
      ? allowNotFound?.customOptionLabel?.[context.lang] || L.questions.search_select.not_found
      : safeParse(props.info[targetField] || '{}')
  );

  // TODO: handle language change?

  useEffect(() => {
    const search = async () => {
      setSearching(true);
      try {
        const res: any = await searchLargeDictionary({
          term: query,
          limit: max_options,
          baseTableName: search_type.baseTableName,
        });

        if (res.error) {
          throw res.error;
        } else {
          setFilteredOptions(res);
        }
      } catch (err) {
        console.log(err);
        toast({
          description: L.questions.search_select.options_error,
          variant: 'error'
        });
      } finally {
        setSearching(false);
      }
    };

    if (query.length > 2) {
      search();
    } else {
      setFilteredOptions([]);
    }
  }, [query]);

  function handleChange(e: any) {
    if (e.target.value) {
      debouncedSetQuery(e.target.value) 
    } else {
      setQuery('');
      updateValue('');
    }
  }

  function updateValue(value: LgDictOption | '') {
    let newValue = '';
    if (value === 'not_found') {
      newValue = 'not_found';
    } else if (value) {
      newValue = JSON.stringify(value);
    };

    setSelectedOption(value || {});
    setFilteredOptions([]);
    setInfoKey(
      targetField,
      newValue,
      !!newValue,
      false
    );
  }

  const formattedContent = useModularMarkdown({
    content: props[languageContent(context.lang)] || '',
    info: props.info,
  });

  if ((props['Additional Options'] || []).indexOf('Hidden') !== -1) {
    return <span></span>;
  }

  const content = (props["Additional Options"] || []).indexOf("Formatted") !== -1
    ? formattedContent
    : <b>{props[languageContent(context.lang)]}</b>;

  const displayValueForInput = (option: LgDictOption) => {
    if (option === 'not_found') {
      return allowNotFound?.customOptionLabel?.[context.lang] || L.questions.search_select.not_found;
    }
    return option[columns.searchColumn] || '';
  };

  const NotFoundOption = () =>
    !isSearching ? (
      <Combobox.Option
        className="relative cursor-pointer px-4 py-2 text-gray-900"
        value="not_found"
      >
        {allowNotFound.customOptionLabel?.[context.lang] || L.questions.search_select.not_found}
      </Combobox.Option>
    ) : (
      <></>
    );

  return (
    <fieldset>
      <legend>
        {content}
      </legend>
      <Combobox value={selectedOption} onChange={(o: LgDictOption) => {updateValue(o)}}>
        <div className="relative">
          <Combobox.Input
            className={`max-w-full block border-solid p-2 rounded-md border-2 focus:ring-gray-400 focus:border-gray-400"
              ${props.info[targetField]
      ? 'border-green-400 ring-green-400'
      : props["Additional Options"]?.includes('Optional')
        ? 'border-gray-200 ring-gray-200'
        : 'border-red-200 ring-red-400'
    }`}
            style={{ width: '26rem' }}
            displayValue={displayValueForInput}
            onChange={handleChange}
          />
          {query?.length >= 3 && (
            <Transition
              as={Fragment}
              leave="transition ease-in duration-500"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              afterLeave={() => setQuery('')}
            >
              <Combobox.Options
                className="max-w-full m-0 py-2 px-0 bg-white border border-gray-200 rounded-md focus:outline-none z-50"
                style={{ width: '26rem' }}
              >
                {isSearching ? (
                  <div className="select-none px-4 py-2">
                    {L.dashboard.searching}
                  </div>
                ) : filteredOptions?.length === 0 ? (
                  !allowNotFound && (
                    <div className="select-none px-4 py-2">
                      {L.dashboard.no_results}
                    </div>
                  )
                ) : (
                  filteredOptions?.map((option: any, i: number) => (
                    <Combobox.Option
                      key={i}
                      className={({ active }) => 
                        `relative cursor-pointer px-4 py-2 ${active ? 'bg-gray-100' : 'text-gray-900'}`
                      }
                      value={option}
                    >
                      {({ active, selected }) => (
                        <>
                          <span className={`block ${selected ? 'font-medium' : 'font-normal'} ${active ? 'whitespace-normal' : 'truncate'}`}>
                            {option[columns.searchColumn]}
                          </span>
                          {selected && (
                            <span className="absolute inset-y-0 left-0 flex items-center pl-0.5">
                              <CheckIcon className="h-5 w-5 text-gray-700" aria-hidden="true" />
                            </span>
                          )}
                        </>
                      )}
                    </Combobox.Option>
                  ))
                )}
                {allowNotFound && <NotFoundOption />}
              </Combobox.Options>
            </Transition>
          )}
        </div>
      </Combobox>
    </fieldset>
  );
}

export {
  SingleSelectQuestion,
  MultipleSelectQuestion,
  ConfirmQuestion,
  SearchSelectQuestion,
  SearchSelectLargeDictionaryQuestion
};
