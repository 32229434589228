import { Root, ExpandedSurvey } from "@aidkitorg/types/lib/survey";
import { useCallback, useEffect, useRef, useState } from "react";
import { usePost } from "./API";
import React from "react";
import { Bars3Icon } from '@heroicons/react/24/solid'
import { SpacedSpinner, useInterval } from "./Util";
import { MIGRATIONS } from "./Migrations";
import { ChangeSet } from "@aidkitorg/typesheets/lib/collab";

const Distro = React.lazy(() => import("@aidkitorg/typesheets/lib/distroeditor"));

export default function ConfigVersionsPage(props: {
  displayCollabVersion?: boolean
}) {
  const [aILoading, setAILoading] = useState(false);
  const [collabEnabled, setCollabEnabled] = useState(false);
  const [diffToCurrent, setDiffToCurrent] = useState('Loading diff...');
  const diffToCurrentVersRef = useRef(['','']);
  const [diffToPrev, setDiffToPrev] = useState('Loading diff...');
  const diffToPrevVersRef = useRef(['','']);
  const [fetchedDate, setFetchedDate] = useState('');
  const [fetchedVersion, setFetchedVersion] = useState<ExpandedSurvey | null>(null);
  const [fetchedVersionEvents, setFetchedVersionEvents] = useState<ChangeSet | null>(null);
  const [showVersionEvents, setShowVersionEvents] = useState(false);
  const [hideJSONDiff, setHideJSONDiff] = useState(false);
  const [name, setName] = useState(window.location.hash.slice(1));
  const [showDiffToCurrent, setShowDiffToCurrent] = useState(false);
  const [showSidebar, setShowSidebar] = useState(true);
  const [survey, setSurvey] = useState<Root>({ survey: [], notifications: [], personas: [] });
  const [versions, setVersions] = useState<VersionInfo[]>([]);
  const currentPublishedVersionRef = useRef('');
  const [loading, setLoading] = useState(false);
  const [diffLoading, setDiffLoading] = useState(false);

  const distroRef = useRef<React.ComponentRef<typeof Distro>>(null);

  const doMagic = usePost('/program/admin/magicV2');
  const loadSurvey = usePost('/survey/load_survey');
  const retrieveSurveyVersion = usePost('/survey/retrieve_survey_version');
  const retrieveCollabVersion = usePost('/survey/retrieve_collab_version');
  const saveSurvey = usePost('/survey/save_survey', { compressRequestPayload: true });
  const saveCollabVersion = usePost('/survey/save_collab_version', { compressRequestPayload: true });
  const listSurveyVersions = usePost('/survey/list_survey_versions');
  const listCollabVersions = usePost('/survey/list_collab_versions');
  const refreshCollabEvents = usePost('/survey/get_collab_events');
  const diffBetweenVersions = usePost('/survey/diff_between_versions_async');
  const [versionsTruncated, setVersionsTruncated] = useState(true);
  const loadingVersionsRef = useRef(false);
  const loadMoreVersionsRef = useRef(null);

  // versionIdMarker and keyMarker are for s3 pagination
  const versionIdMarker = useRef('');
  const keyMarker = useRef('');

    type VersionInfo = {
      versionId: string;
      lastModified: string;
      isLatest: boolean | undefined;
      lastModifiedUser: string | undefined;
    }

    useEffect(() => {
      load(name);
      loadVersions()
    }, [name]);

    async function loadVersions({append = false}: {append?: boolean} = {}) {
      if (!name) return; 

      let listFn = props.displayCollabVersion ? listCollabVersions : listSurveyVersions;
      loadingVersionsRef.current = true;
      const versionsResult = await listFn({
        name: name,
        pageSize: 50,
        versionIdMarker: versionIdMarker.current,
        keyMarker: keyMarker.current,
      }) as {
        list: VersionInfo[];
        keyMarker: string;
        versionIdMarker: string;
        isTruncated: boolean;
      }
      if (versionsResult) {
        keyMarker.current = versionsResult.keyMarker;
        versionIdMarker.current = versionsResult.versionIdMarker;
        setVersionsTruncated(versionsResult.isTruncated)
        if (append) {
          // append is used for paginating additional results
          setVersions(prev => [...prev, ...versionsResult.list]);
        } else {
          setVersions(versionsResult.list);
          onSelectVersion(versionsResult.list[0], versionsResult.list);
        }
      }

      if (props.displayCollabVersion) {
        const publishedVersionsResult = await listSurveyVersions({
          name: name,
          pageSize: 50,
          versionIdMarker: '',
          keyMarker: '',
        }) as {
          list: VersionInfo[];
          keyMarker: string;
          versionIdMarker: string;
          isTruncated: boolean;
        }

        if (publishedVersionsResult) {
          currentPublishedVersionRef.current = publishedVersionsResult.list[0].versionId;
        }
      }

      loadingVersionsRef.current = false;
    }

    useEffect(() => {
      // This gets triggered when the element with ref={loadMoreVersionsRef} is in view
      const observer = new IntersectionObserver((entries) => {
        const first = entries[0];
        if (first.isIntersecting && keyMarker.current && versionIdMarker.current && !loadingVersionsRef.current) {
          loadVersions({append: true}).then(() => {
            // Reobserve the element after loading is complete in case it's still in view
            if (!loadingVersionsRef.current && loadMoreVersionsRef.current) {
              observer.observe(loadMoreVersionsRef.current);
            }
          });
        }
      }, {});
      if (loadMoreVersionsRef.current) {
        observer.observe(loadMoreVersionsRef.current);
      }
      return () => {
        // On cleanup, disconnect observer
        observer.disconnect();
      };
    }, [loadMoreVersionsRef, loadingVersionsRef.current]);

    useEffect(() => {
      if (distroRef.current) {
        distroRef.current?.initialize(fetchedVersion as Root);
      }
    }, [fetchedVersion]);

    async function load(_n?: string) {
      let n = _n || name;
      if (!n) {
        let _n = prompt('Enter a name to load');
        if (_n) {
          n = _n;
          setName(_n);
        }
      }
      if (!n) {
        return;
      }
      window.location.hash = '#' + n;
      const surveyResp = await loadSurvey({ name: n })
      if (!surveyResp) {
        alert('Error loading survey');
        return;
      }
      setSurvey(surveyResp.config);

      const collabResp = await refreshCollabEvents({ name: n });
      if (collabResp.collabEnabled) {
        setCollabEnabled(true);
      } else {
        setCollabEnabled(false);
      }
    }

    useInterval(() => {
      (async () => {
        if (!name) return;
        const collabResp = await refreshCollabEvents({ name });
        if (collabResp.collabEnabled) {
          setCollabEnabled(true);
        } else {
          setCollabEnabled(false);
        }
      })();
    }, 3000);

    async function onSelectVersion(versionInfo: VersionInfo, versionsCurrent: VersionInfo[]) {
      setDiffLoading(true)
      setLoading(true)
      setFetchedDate(versionInfo.lastModified);
      const versionIndex = versionsCurrent.findIndex((v) => v.versionId === versionInfo.versionId);
      const prevVersionIndex = versionIndex !== -1 && versionIndex < versionsCurrent.length - 1 ? versionIndex + 1 : -1;

      let version;
      let versionAsEvents;
      if (props.displayCollabVersion) {
        let res = await retrieveCollabVersion({ name: name, versionId: versionInfo.versionId });
        version = res.survey;
        versionAsEvents = res.events;
      } else {
        version = await retrieveSurveyVersion({ name: name, versionId: versionInfo.versionId });
      }

      if (props.displayCollabVersion && currentPublishedVersionRef.current) {
        diffToCurrentVersRef.current = [versionInfo.versionId, currentPublishedVersionRef.current];
      } else {
        diffToCurrentVersRef.current = [versionInfo.versionId, versionsCurrent[0].versionId];
      }

      diffToPrevVersRef.current = [prevVersionIndex !== -1 ? versionsCurrent[prevVersionIndex].versionId : '', versionInfo.versionId];

      if (version) setFetchedVersion(JSON.parse(version) as ExpandedSurvey);
      if (versionAsEvents) setFetchedVersionEvents(versionAsEvents);
      setLoading(false)
      const retryWithTimeout = async (versionId1: string, versionId2: string, promiseFn: () => Promise<any>): Promise<any> => {
        let retries = 0;
        while (retries < 20) { // try for 100 seconds. 20 * 5 seconds
          console.log('trying ' + retries)
          try {
            const result = await promiseFn();
            if (result.diff !== 'waiting_for_diff') {
              return result;
            }
          } catch (error) {
            console.error('Error while retrying:', error);
          }
          await new Promise(resolve => setTimeout(resolve, 5000)); // wait 5 seconds then try again
          retries++;
        }
        return {
          diff: 'This is taking too long, please try again later or contact support if you continue to see this issue.',
          versionId1,
          versionId2
        };
      }

      const diffPromises = [
        retryWithTimeout(
          prevVersionIndex !== -1 ? versionsCurrent[prevVersionIndex].versionId : '',
          versionInfo.versionId,
          () => diffBetweenVersions({
            name: name,
            versionId1: prevVersionIndex !== -1 ? versionsCurrent[prevVersionIndex].versionId : '',
            versionId2: versionInfo.versionId,
            options: { color: false, collab: props.displayCollabVersion }
          })
        ),
        retryWithTimeout(
          versionInfo.versionId,
          versionsCurrent[0].versionId,
          () => diffBetweenVersions({
            name: name,
            versionId1: versionInfo.versionId,
            versionId2: (props.displayCollabVersion && currentPublishedVersionRef.current) ? currentPublishedVersionRef.current : versionsCurrent[0].versionId,
            options: { color: false, collab: props.displayCollabVersion, comparingCurrentVersion: true }
          })
        ),
      ]
      const [diffToPrevResponse, diffToCurrentResponse] = await Promise.all(diffPromises);

      // Check that the fetched version matches the latest selected version before setting state in case selection has changed.
      if (diffToPrevResponse.versionId1 === diffToPrevVersRef.current[0] && diffToPrevResponse.versionId2 === diffToPrevVersRef.current[1]) {
        setDiffToPrev(diffToPrevResponse.diff ? diffToPrevResponse.diff : 'No Changes');
      }
      if (diffToCurrentResponse.versionId1 === diffToCurrentVersRef.current[0] && diffToCurrentResponse.versionId2 === diffToCurrentVersRef.current[1]) {
        setDiffToCurrent(diffToCurrentResponse.diff ? diffToCurrentResponse.diff : 'No Changes');
      }
      setDiffLoading(false);
    }

    (window as any).magic = async (data: any) => {
      return await doMagic(data);
    }

    async function AISummary() {
      // get full diff so AI has more context
      const diff = showDiffToCurrent ? diffToCurrent : diffToPrev;
      const prompt_eng = `Write a concise summary of the changes that were made to this json, which represents a survey configuration.
            It was created with the json-diff library using the {full: true} option. Since the intended audience isn't familiar with json,
            please use plain english, refer to the json as a "survey configuration",
            and don't mention the + or - as users don't see those. If there were no changes say "No Changes".
            When you're done say STOP ${diff}`

      setAILoading(true);
      const response = await (window as any).magic({
        model: "gpt-4o",
        message: prompt_eng,
        max_tokens: 1000,
        top_p: 1,
        frequency_penalty: 0,
        presence_penalty: 0,
        stop: "STOP",
        temperature: 0,
      });
      if (response.choices?.[0]?.message?.content) {
        alert(response.choices[0]?.message?.content);
      } else {
        console.log(response);
        alert('Uh oh, we had trouble with your request. Try again!')
      }
      setAILoading(false);
    }

    const restoreVersion = useCallback(async () => {
      if (props.displayCollabVersion) {
        const confirmRestore = confirm(
          'Are you sure you want to restore this auto-saved version? \
                \n \nThis will NOT change the published survey, but it will clear any in-progress changes not included in this version. \
                \n \nBe sure to coordinate this action with your team, and refresh your distro page before resuming work!!')

        if (confirmRestore) {
          // set s3 keys to empty so we refetch first page of versions
          keyMarker.current = '';
          versionIdMarker.current = '';

          alert(JSON.stringify(await saveCollabVersion({
            name,
            events: JSON.stringify(fetchedVersionEvents),
            restoringEarlierVersion: true
          })));
                
          load(name);
          loadVersions();
        }
      } else if (collabEnabled) {
        // In collab mode, the survey is saved in the form of an .ops file, not the straight up published survey json. 
        // Because of this, if we restore a published version, the collab distro state will not change to reflect this. 
        // So for now, we prohibit this.
        alert('You cannot restore a published version while collab is enabled. Please disable collab and try again.');
      } else {
        const confirmRestore = confirm(
          'Are you sure you want to restore this version? \
                \n \nThis will overwrite the current configuration for this program with the version selected here \
                \n \nBe sure to coordinate this action with your team!!')

        if (confirmRestore) {
          // set s3 keys to empty so we refetch first page of versions
          keyMarker.current = '';
          versionIdMarker.current = '';

          alert(JSON.stringify(await saveSurvey({ 
            name: name, 
            content: JSON.stringify(fetchedVersion),
          })));

          load(name);
          loadVersions();
        }
      }
    }, [fetchedVersion, fetchedVersionEvents, collabEnabled]);

    const dateOptions: Intl.DateTimeFormatOptions = {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: true
    };
    const formatDate = (date: string) => {
      const dateFormatter: Intl.DateTimeFormat = new Intl.DateTimeFormat('en-US', dateOptions);
      try {
        return dateFormatter.format(new Date(date));
      }
      catch (e) {
        return '';
      }
    }

    return <>
      <div className="flex h-12 border-b border-blue-300 z-[60] relative top bg-blue-200">
        <div className="font-bold text-2xl text-blue-400 ml-3 mt-2">
          {props.displayCollabVersion ? 'Auto-Saved' : 'Published'} Distro History: <span className="border-0 bg-transparent ml-2 mr-3 text-blue-600 font-normal">{name}</span>
        </div>
        <a href={'/config#' + name} className="border-0 bg-transparent p-2 text-blue-600 hover:text-blue-800 ml-auto">
          Back to Configuration
        </a>
      </div>
      {name && <div className="flex" style={{ height: 'calc(100vh - 6.5rem)' }}>
        <div className="overflow-y-auto w-full">
          <div className="sticky top-0 bg-white px-4 py-0 z-[60] border-b shadow-sm">
            <div className="flex items-center">
              <div>
                <div className="text-lg pt-4">{formatDate(fetchedDate)}</div>
                <div className="d-block text-xs">Below is a JSON log of the changes saved {showDiffToCurrent ? 'since the selected version' : 'in the selected version'}</div>
              </div>
              {!showSidebar &&
                            <button 
                              title="Open Sidebar"
                              onClick={() => setShowSidebar(!showSidebar)} 
                              className="border-0 bg-transparent h-2 w-2 text-blue-600 hover:text-blue-800 ml-auto pr-3 mb-6">
                              <Bars3Icon className="h-7 w-7 mr-6"/>
                            </button>}
            </div>
            <div className="flex items-center">
              <input
                type="checkbox"
                id="hide-diff"
                className="form-checkbox h-3 w-3 cursor-pointer"
                checked={hideJSONDiff}
                onChange={() => setHideJSONDiff(!hideJSONDiff)}
              />
              <label htmlFor="hide-diff" className="ml-1 text-sm mb-0 cursor-pointer">
                Hide JSON
              </label>
              <span className="text-gray-500 pb-1 text-lg mr-2 ml-2">|</span>
              <input
                type="checkbox"
                id="show-diff"
                className="form-checkbox h-3 w-3 cursor-pointer"
                checked={showDiffToCurrent}
                onChange={() => setShowDiffToCurrent(!showDiffToCurrent)}
              />
              <label htmlFor="show-diff" className="ml-1 text-sm mb-0 cursor-pointer">
                Compare to current {props.displayCollabVersion ? 'published ' : ''}config
              </label>
              {props.displayCollabVersion && <>
                <span className="text-gray-500 pb-1 text-lg mr-2 ml-2">|</span>
                <input
                  type="checkbox"
                  id="show-events"
                  className="form-checkbox h-3 w-3 cursor-pointer"
                  checked={showVersionEvents}
                  onChange={() => setShowVersionEvents(!showVersionEvents)}
                />
                <label htmlFor="show-events" className="ml-1 text-sm mb-0 cursor-pointer">
                  View Saved Collab Ops
                </label>
              </>}

              <button onClick={AISummary} className="border-0 bg-transparent p-2 text-blue-600 hover:text-blue-800 ml-auto text-sm">
                {!aILoading ? 'Ask AI to summarize changes' : <SpacedSpinner />}
              </button>
              <span className="text-gray-500 pb-1 text-lg">|</span>
              <button onClick={restoreVersion} className="border-0 bg-transparent p-2 text-blue-600 hover:text-blue-800 text-sm">
                Restore this version
              </button>
            </div>
          </div>
          <div>
            <div className="p-3">
              {!hideJSONDiff && <pre className="bg-gray-100 p-8 text-xs">
                <code>
                  { diffLoading ? <SpacedSpinner /> : (
                    showVersionEvents 
                      ? JSON.stringify(fetchedVersionEvents || [], null, 2)
                      : showDiffToCurrent 
                        ? diffToCurrent 
                        : diffToPrev
                  )}
                </code>
              </pre>}
            </div>
          </div>
          {!loading && <Distro ref={distroRef} types='src/survey.ts' name='Root' migrations={MIGRATIONS} />}
        </div>
        {showSidebar && <div className="sidebar overflow-y-auto w-96 border">
          <div className="sticky top-0 bg-white p-4 border-b shadow-sm">
            <button 
              title="Close Sidebar"
              onClick={() => setShowSidebar(!showSidebar)} 
              className="text-lg border-0 bg-transparent">
              Saved Versions
            </button>
          </div>
          <ul className="flex-grow-1 space-y-2">
            {(versions || []).map((s) => {
              const lastModified = formatDate(s.lastModified);
              return <li
                key={s.lastModified}
                onClick={() => onSelectVersion(s, versions)}
                className={`my-0 py-2 px-4 cursor-pointer text-sm border-solid border-gray-300 border-t-1 border-x-0 border-b-0 ${formatDate(fetchedDate) === lastModified ? 'bg-blue-200' : ''}`}
              >
                <div>{lastModified}</div>
                {s.lastModifiedUser && <div>{s.lastModifiedUser}</div>}
              </li>
            })}
            <li ref={loadMoreVersionsRef} className="flex justify-center items-center">
              { versionsTruncated && <SpacedSpinner /> }
            </li>
          </ul>
        </div>}
      </div>}
    </>
};
