import { useRef, useState, useCallback, useEffect } from "react";

/** Constants for Recording */
const MAX_CHUNKS = 8;
const CAPTURE_INTERVAL = 500;

export type timestampedBlob = {
  i: number, // [deprecated, removing on next release]
  timestamp: number,
  data: Blob
}


const stopRecorder = (recording: MediaRecorder) => {
  if (recording.state === 'recording') {
    recording.stop();
  }
}

// Custom hook to manage interval recording
export const useIntervalRecording = (stream: React.MutableRefObject<MediaStream | null>, log?: string[]) => {

  const recordedChunks = useRef([] as timestampedBlob[]);
  const mediaRecorders = useRef([] as MediaRecorder[]);
  const [captureReady, setCaptureReady] = useState(false);
  const chunks = useRef([] as timestampedBlob[]);
  const i = useRef(0);

  const chosenCodec = useRef('');

  const codecs = [
    'video/webm; codecs="vp8"',
    'video/webm; codecs="vp9"',
    'video/webm; codecs="av1"',
    'video/mp4; codecs="avc1.42E01E"', // Baseline profile for H.264
    'video/mp4; codecs="avc1.64001F"', // Main profile for H.264
    'video/mp4; codecs="avc1.640028"', // High profile for H.264
    'video/mp4; codecs="hev1.1.6.L93.B0"', // HEVC (H.265)
    'video/mp4; codecs="hvc1.1.6.L93.B0"', // HEVC (H.265)
    'video/ogg; codecs="theora"'
  ];

  const startNewRecording = useCallback(() => {
    if (!stream.current) {
      log?.push("Waiting for stream");
      return; 
    }

    log?.push(`Creating Recorder ${i.current}`);

    let mediaRecorder = (() => {
      let recorder: MediaRecorder | null;
      for (let codec of codecs) {
        try {
          recorder = new MediaRecorder(stream.current, {
            mimeType: codec, 
            videoBitsPerSecond: 2500000
          });
          chosenCodec.current = codec;
          break;
        } catch (e) {
          console.log("Codec doesnt work with device: ", e, codec, navigator?.userAgent);
        }
      }
      if (!recorder!) throw new Error("Device not supported");
      return recorder;
    })()
    let current = i.current; 
  
    mediaRecorder.ondataavailable = (event) => {
      let { data } = event;
      if (data.size > 0) {
        chunks.current.push({ 
          i: current, 
          timestamp: Date.now(),
          data,
        });
        if (chunks.current.length > 2 * MAX_CHUNKS * MAX_CHUNKS) {
          chunks.current.shift();
        }
        
        recordedChunks.current = chunks.current;
        log?.push(`Pushed chunks: ${chunks.current.length} for recorder ${current}`);
      } else {
        log?.push(`No data available: ${chunks.current.length} for recorder ${current}`);
        console.warn("No data available", event);
      }

      if (recordedChunks.current.length >= MAX_CHUNKS) {
        log?.push("Setting capture ready = true")
        setCaptureReady(true);
      } else {
        log?.push("Setting capture ready = false")
        setCaptureReady(false);
      }

    };

    try {
      if (stream.current) mediaRecorder.start(CAPTURE_INTERVAL);
    } catch (e) {
      console.warn("Error starting recorder:", e);
    }

    setTimeout(() => {
      stopRecorder(mediaRecorder);
    }, MAX_CHUNKS * CAPTURE_INTERVAL);

    i.current++;
    mediaRecorders.current.push(mediaRecorder);

    if (mediaRecorders.current.length > MAX_CHUNKS) {
      const oldMediaRecorder = mediaRecorders.current.shift();
      if (oldMediaRecorder) stopRecorder(oldMediaRecorder);
    }

  }, [stream.current]);

  useEffect(() => {
    startNewRecording();
    const intervalId = setInterval(startNewRecording, CAPTURE_INTERVAL * MAX_CHUNKS);
    return () => {
      clearInterval(intervalId);
      mediaRecorders.current.forEach((mediaRecorder) => {
        stopRecorder(mediaRecorder);
      });
      recordedChunks.current = [];
      chunks.current = [];
    };
  }, [startNewRecording, stream.current]);

  return { recordedChunks, codec: chosenCodec.current, captureReady };
};
