import * as survey from "@aidkitorg/types/lib/survey";
import React, { ReactNode, Suspense, useCallback, useContext, useEffect, useRef, useState } from "react";
import { usePost } from "./API";
import Papa from "papaparse";
import { ConfigurationContext, PublicConfigurationContext } from "./Context";
import { BUTTON_CLASS, AidKitLogo, SpacedSpinner } from "./Util";
import { Link } from 'react-router-dom';
import { snakeCase } from 'lodash';
import { useToast } from "@aidkitorg/component-library";
import { captureException } from "@sentry/react";

const Distro = React.lazy(() => import("@aidkitorg/typesheets/lib/distroeditor"));

type ButtonProperties = {
  label: string,
  [key: string]: any
}
export function Button(props: ButtonProperties): JSX.Element {
  return <button {...props} className="block w-full tracking-wider rounded-md pt-3 pb-2 text-green-700 shadow-sm border-gray-300 bg-green-300 hover:text-green-100 hover:bg-green-400 font-logo text-2xl">{props.label}</button>
}

function utf8_to_b64(str: string) {
  return window.btoa(unescape(encodeURIComponent(str)));
}

function b64_to_utf8(str: string) {
  return decodeURIComponent(escape(window.atob(str)));
}

function tryDecodingHash(hash: string): HashData | null {
  try {
    const decoded = JSON.parse(b64_to_utf8(hash));
    if (typeof decoded === 'object' && decoded !== null) {
      if (decoded?.columns) {
        return {
          config: decoded,
          groupByField: null,
          nameField: null,
          autoColumns: false,
        } as HashData;
      }
    }
    return decoded as HashData;
  } catch (e) {
    return null;
  }
}

interface HashData {
  config: survey.CSVMapping;
  groupByField: string | null;
  nameField: string | null;
  autoColumns: boolean;
}

export function ImportPage() {
  const configContext = useContext(ConfigurationContext);
  const publicConfig = useContext(PublicConfigurationContext);

  const importCSV = usePost('/program/admin/import_csv');
  const importCSVStatus = usePost('/program/admin/async_job_status');
  const createImportSignature = usePost('/program/admin/create_import_signature');
  const initialHash: HashData = tryDecodingHash(window.location.hash.slice(1)) || {
    config: {
      mode: {
        kind: 'Update',
        uidColumn: ''
      },
      columns: [] as survey.CSVMapping['columns']
    },
    groupByField: null,
    nameField: null,
    autoColumns: false,
  };
  const defaultConfig = {
    mode: {
      kind: 'Update',
      uidColumn: ''
    },
    columns: [] as survey.CSVMapping['columns']
  };
  const [config, setConfig] = useState<survey.CSVMapping>(initialHash.config || defaultConfig);
  const [allowedFields, setAllowedFields] = useState('');
  const [generatedImportUrl, setGeneratedImportUrl] = useState('');
  const [autoColumns, setAutoColumns] = useState<boolean>(initialHash.autoColumns || false);
  const [groupByField, setGroupByField] = useState<string | null>(initialHash.groupByField || null);
  const [nameField, setNameField] = useState<string | null>(initialHash.nameField || null);
  const [data, setData] = useState<Record<string, string>[] | null>(null);
  const [grouped, setGrouped] = useState(false);
  const [file, setFile] = useState<File>();
  const [importing, setImporting] = useState(false);
  const [taskId, setTaskId] = useState<string | null>(null);
  const [serverValidationError, setServerValidationError] = useState('');

  const [errors, setErrors] = useState<ErrorHolder>({});
  const [formattedData, setFormattedData] = useState<Record<string, string | ReactNode>[][]>([]);
  const { toast } = useToast();

    // This is just another way to say Record<string, Record<string, string[]>>
    type ErrorHolder = {
      [rowNumber: string]: {
        [columnIndex: string]: string[]
      }
    }
    const formatValue = (column: survey.CSVMapping['columns'][number], csvValue: string | Record<string, string>[], rowNumber: number, columnIndex: number, errors: ErrorHolder): string | ReactNode => {
      const addError = (errorMessage: string) => {
        ((errors[rowNumber] ??= {})[columnIndex] ??= []).push(`Error in row ${rowNumber}, column ${column.targetField}: ${errorMessage}`);
      }

      let value = csvValue;

      if (Array.isArray(value)) {
        // this means it's from a grouping and needs to be formated into a subtable for preview.
        const header = new Set(Object.keys(value[0]));
        for (const row of value) {
          const rowKeys = Object.keys(row);
          for (const key of rowKeys) {
            header.add(key);
          }
        }
        const headerArray = Array.from(header);
        const formatted = value.map(row => {
          return Object.fromEntries(headerArray.map(k => [k, row[k] || '']))
        })
        return <table className="min-w-full bg-white border">
          <thead className="bg-gray-50 border-b">
            <tr>
              {headerArray.map((k, i) => <th key={i} className="px-4 py-2 text-left text-xs font-medium text-gray-500 lowercase tracking-wider">{k}</th>)}
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {formatted.map((row, rowIndex) => (
              <tr key={rowIndex} className="bg-white">
                {headerArray.map((k, i) => <td key={i} className="px-4 py-2 whitespace-nowrap text-sm text-gray-700">{row[k]}</td>)}
              </tr>
            ))}
          </tbody>
        </table>
      }

      value = (value || '').trim();

      // Data validation and clean-up
      if (column.criteria === 'exists' && !value) {
        addError('Value must not be empty');
        return value;
      }

      if (config.mode.kind === 'Update' && config.mode.uidColumn === column.csvColumn && !value) {
        addError('Missing UID');
        return value;
      }

      if (config.mode.kind === 'Create' && config.mode.nameColumn === column.csvColumn && !value) {
        addError('Missing Name');
        return value;
      }

      if (column.format === 'As Is') {
        // Do nothing, leave as is
      } else if (column.format === 'Phone') {
        const valueAllDigits = value.replace(/\D/g, '');
        value = value.replace(/(?!^\+)\D/g, '');
        if (value && (valueAllDigits.length < 10 || valueAllDigits.length > 13)) {
          addError('Phone must be between 10 and 13 digits');
        }
      } else if (column.format === 'Date') {
        value = value.split(/\/|-/).map((v) => v.padStart(2, '0')).join('/');
      } else if (column.format === 'Email') {
        value = value.toLowerCase();
      } else if (typeof column.format === 'object') {
        let found = false;
        for (const option of column.format.options) {
          if (value === option.csvValue) {
            value = option.targetFieldValue;
            found = true;
          }
        }
        if (!found) {
          if (!column.format.allowUnknownValues) {
            addError('Unknown value');
          }
        }
      }

      return value;
    };

    useEffect(() => {
      if (data) {
        const localErrors: ErrorHolder = {};
        const formatted = data.map((row, rowIndex) =>
          config.columns.map((column, columnIndex) => ({
            [column.targetField]: formatValue(column, row[column.csvColumn] || '', rowIndex, columnIndex, localErrors)
          }))
        );
        setErrors(localErrors);
        setFormattedData(formatted);
      } else {
        setErrors({});
        setFormattedData([]);
      }
    }, [data, config.columns]);

    const [previewing, setPreviewing] = useState(false);

    let distroRef = useRef<React.ComponentRef<typeof Distro>>(null);
    let refsLoaded = useRef(false);

    const transform = (entry: any, index: number) =>
      Object.fromEntries(
        Object.entries<any>({
          ...entry,
          legal_name: `Entry N${index}`
        }).map(
          ([k, v]) => ([snakeCase(k), v])
        )
      );

    const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
      const fileToUpload = e.target.files?.[0];
      if (fileToUpload) {
        const fileExtension = fileToUpload.name.split('.').pop();
        if (fileExtension === 'csv') {
          setFile(fileToUpload);
        } else {
          alert('File must be a .csv\nUploaded file was .' + fileExtension);
          setFile(undefined);
          // This clears the input to prevent confusing behavior if the same file is uploaded twice in a row
          e.target.value = '';
        }
      }
    };

    useEffect(() => {
      if (file) {
        Papa.parse<any>(file, {
          header: true,
          skipEmptyLines: true,
          complete: function (results) {
            if (results.errors.length > 1) {
              alert(JSON.stringify(results.errors));
              return;
            }
            // Trim header keys in each row
            const trimmedData = results.data.map((row: Record<string, any>) => {
              return Object.fromEntries(
                Object.entries(row).map(([key, value]) => [key.trim(), value])
              );
            });

            if (groupByField && nameField) {
              const map = trimmedData.reduce((prev: Map<string, any>, cur) => {
                if (!prev.has(cur[groupByField])) {
                  prev.set(cur[groupByField], [])
                }
                prev.get(cur[groupByField])!.push(cur);
                return prev;
              }, new Map<string, any[]>())

              setData(Array.from(map).map(
                ([k, v]) => ({
                  [groupByField]: k,
                  [nameField]: v[0][nameField],
                  Records: v.map(transform)
                })
              ));
              setGrouped(true);
            } else {
              setData(trimmedData);
            }
          },
        });
      }
    }, [file, groupByField, nameField]);

    useEffect(() => {
      if (data && data.length > 0) {
        // create key-value object to lookup existing column configurations
        const mappingsByKey: Record<string, survey.CSVMapping['columns'][number]> = {};
        for (const col of config.columns) {
          mappingsByKey[col.csvColumn] = col;
        }

        const newConfig: survey.CSVMapping = {
          mode: (grouped && nameField && groupByField) ? {
            kind: 'Create',
            nameColumn: nameField,
            hashedFromColumn: groupByField
          } : config.mode,
          columns: autoColumns ? Object.keys(data[0]).map(k => ({
            kind: 'FieldMapping',
            csvColumn: k,
            // try and get format existing config, fall back to 'As Is'
            format: mappingsByKey[k]?.format ?? 'As Is',
            targetField: nameField === k ? 'legal_name' : snakeCase(k)
          })) : [...(config.columns ?? [])]
        }
        setConfig(newConfig);
        distroRef.current?.initialize(newConfig, undefined, undefined, undefined, {
          'csvColumns': Object.keys(data[0])
        });
      }
    }, [data, autoColumns]);

    // updating url hash is a side effect of config changes
    useEffect(() => {
      const newHash: HashData = {
        config,
        groupByField,
        nameField,
        autoColumns,
      };
      window.location.hash = '#' + utf8_to_b64(JSON.stringify(newHash));
    }, [config, groupByField, nameField, autoColumns]);

    const doImport = async () => {
      setImporting(true);
      const count = data?.length || 0;

      const urlParams = new URLSearchParams(window.location.search);
      const taskResponse = (await importCSV({
        data: data!,
        mapping: config,
        import_signature: urlParams.get('import_signature') || '',
      }))
      if (taskResponse && taskResponse.taskId) {
        setTaskId(taskResponse.taskId);
      } else if (taskResponse.validationError) {
        setServerValidationError(taskResponse.validationError);
        setImporting(false);
      } else {
        setImporting(false);
        toast({
          description: "Import failed to enqueue",
          variant: "error"
        })
      }
    };

    const pollForImportFinish = useCallback(async () => {
      if (taskId) {
        const statusResponse = await importCSVStatus({ taskId });
        if (statusResponse.status === 'FINISHED') {
          setTaskId(null);
          setImporting(false);
          setPreviewing(false);
          toast({
            description: "Import finished",
            variant: "success"
          })
        } else if (statusResponse.status === 'FAILED') {
          setTaskId(null);
          setImporting(false);
          toast({
            description: "Import failed to complete",
            variant: "error"
          });
          console.log('async_import_failed: ' + taskId)
          captureException(new Error('Async import failed'), { extra: { taskId } });
        }
      }
    }, [taskId, importCSVStatus]);

    useEffect(() => {
      if (taskId) {
        const intervalId = setInterval(pollForImportFinish, 1000);
        return () => clearInterval(intervalId);
      }
    }, [taskId, pollForImportFinish]);

    const applicantFacingLogo = publicConfig.interface?.applicantFacingLogo?.url || configContext.applicant_facing_logo;
    const applicantFacingLogoWidth = publicConfig.interface?.applicantFacingLogo?.width || configContext.applicant_facing_logo_width || '50';
    const programName = publicConfig.name || configContext.program_name;

    const invalidMessage = config.mode.kind === 'Create' && !config.mode.nameColumn.length ? 'Name column is required for creating new applicants'
      : config.mode.kind === 'Update' && !config.mode.uidColumn ? 'UID column is required for updating existing applicants'
        : !data ? 'Upload a csv to continue'
          : '';

    const hasErrors = Object.keys(errors).length > 0;

    return <div className="bg-gray-100 p-4">
      {/* banner to indicate which program */}
      <div className="import-banner -m-6 mb-4 px-4 py-2 bg-gray-200 flex gap-4 items-center border-gray-300 border-solid border-b border-l-0 border-t-0 border-r-0">
        <Link to={"/"}>
          {
            applicantFacingLogo ? (
              <img
                src={applicantFacingLogo}
                width={applicantFacingLogoWidth}
                style={{ maxHeight: 30 }}
                alt={programName}
              />
            ) : (
              <AidKitLogo width={60} height={30} />
            )
          }
        </Link>
        <h2 className="text-sm m-0">{programName}</h2>
      </div>

      <h2>Import</h2>
      { previewing ?
        <div className="p-10">
          <div>
            <div className="mt-3 text-center sm:mt-5">
              <h3>
                Import Preview
              </h3>
              <div className="mt-2">
                <p className="text-sm text-gray-500">
                  You are about to {config.mode.kind === 'Create' ? 'create' : 'update'} <strong>{formattedData.length}</strong> record(s) in the <strong>{programName}</strong> program. Review the data below.
                </p>
              </div>
            </div>
            {formattedData.length > 0 && <div className="mt-4 overflow-auto">
              <table className="min-w-full bg-white border">
                <thead className="bg-gray-50 border-b">
                  <tr>
                    <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 lowercase tracking-wider">#</th>
                    {config.columns.map((column, index) => (
                      <th key={index} className="px-4 py-2 text-left text-xs font-medium text-gray-500 lowercase tracking-wider">
                        {column.targetField}
                        {config.mode.kind === 'Create' && column.targetField === config.mode.nameColumn ? ' (name column)' : ''}
                        {config.mode.kind === 'Update' && column.targetField === config.mode.uidColumn ? ' (uid column)' : ''}
                        {column.encryptThisValue ? ' (will be encrypted)' : ''}
                        {column.criteria === 'exists' ? ' (Required)' : ''}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {formattedData.map((row, rowIndex) => (
                    <tr key={rowIndex} className={`${errors[rowIndex] ? 'bg-red-100' : 'bg-white'}`}>
                      <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-700">
                        {rowIndex}
                      </td>
                      {row.map((cell, colIndex) => (
                        <td key={colIndex} className={`px-4 py-2 whitespace-nowrap text-sm text-gray-700 ${errors[rowIndex]?.[colIndex] ? 'outline outline-offset-[-4px] outline-red-500' : ''}`}>
                          {Object.values(cell)[0]}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>}
            {(hasErrors || serverValidationError) && <div className="mt-3">Fix the following errors in your csv to proceed:</div>}
            {hasErrors && (
              <div className="text-red-500">
                <ul>
                  {Object.keys(errors).map((key, i) =>
                    Object.keys(errors[key]).map((err, j) =>
                      <li key={key + i + j}>{errors[key][err]}</li>
                    )
                  )}
                </ul>
              </div>
            )}
            <div className="text-red-500">{serverValidationError}</div>
          </div>
          <div className="sm:flex sm:flex-row">
            <button
              type="button"
              className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
              onClick={() => {
                setPreviewing(false)
                setServerValidationError('')
              }}
            >
              Cancel
            </button>
            <button
              type="button"
              className={`mt-2 ${hasErrors ? "opacity-50 cursor-not-allowed" : ""} mt-3 inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm`}
              onClick={(e) => doImport()}
              disabled={hasErrors}
            >
              {importing ? <SpacedSpinner /> : 'Import Data'}
            </button>
          </div>
        </div>
        :
        <div>
          <ul role="list" className="space-y-4">
            <li>
              <h3>Choose File</h3>
              <label htmlFor="file-upload">
                <span className={BUTTON_CLASS}>Upload a CSV file</span>
                <input onChange={handleFileUpload} id="file-upload" name="file-upload" type="file" className="sr-only" />
              </label>
              <span className="px-3">
                {!!file && <span>Chosen file: {<strong>{file.name}</strong>}</span>}
              </span>
            </li>
            <li hidden={!data?.length} className="space-y-2 border p-2 rounded-md w-max max-w-full">
              <details className="max-w-xl" open={!!(groupByField || nameField)}>
                <summary>Grouping Options</summary>
                <div className="flex flex-col gap-2">
                  <div>
                    <div className="mt-2 flex rounded-md shadow-sm">
                      <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 px-3 text-gray-700 sm:text-sm">Group By Field:</span>
                      <select className="block text-center w-full flex-1 rounded-none rounded-r-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 sm:min-w-[20em]"
                        value={groupByField || undefined}
                        onChange={(e) => setGroupByField(e.target.value)}>
                        <option>Select...</option>
                        {Object.keys(data?.[0] ?? {}).map((col, i) => <option key={col + i} value={col}>{col}</option>)}
                      </select>
                    </div>
                    <div className="text-xs px-2 mt-1">When selected, every row that shares this value will be collapsed and grouped into a single row. Each of the those constituent rows will be represented as a nested JSON in a new column named <code>Records</code>.</div>
                  </div>

                  <div>
                    <div className="mt-2 flex rounded-md shadow-sm">
                      <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 px-[27px] text-gray-700 sm:text-sm">Name Field:</span>
                      <select className="block text-center w-full flex-1 rounded-none rounded-r-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 sm:min-w-[20em]"
                        value={nameField || undefined}
                        onChange={e => setNameField(e.target.value)}>
                        <option>Select...</option>
                        {Object.keys(data?.[0] ?? {}).map((col, i) => <option key={col + i} value={col}>{col}</option>)}
                      </select>
                    </div>
                    <div className="text-xs px-2 mt-1">When used with "Group By Field" field above, this field is used to determine the name value for each collapsed row formed by the grouping process. It will always take the name field value from the first constituent row.</div>
                  </div>

                </div>
                <button hidden={!grouped} className="rounded-md bg-red-400 ml-2 p-2" onClick={() => {
                  setGroupByField(null);
                  setNameField(null);
                  setGrouped(false);
                }}>
                  Reset
                </button>
              </details>

            </li>
            <li className="w-max rounded-md">
              <div className="mt-2 flex rounded-md shadow-sm">
                <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 py-2 px-2.5 text-gray-700 sm:text-sm">Add All Columns:</span>
                <input className="block cursor-pointer rounded-md min-w-[2em] border-0 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" onChange={e => {
                  if (!e.target.checked || !config.columns?.length || window.confirm('This will overwrite your existing mapping configuration, are you sure you wish to proceed?')) {
                    setAutoColumns(e.target.checked);
                  }
                }} type="checkbox" checked={autoColumns} />
              </div>
            </li>
          </ul>
          {(data || initialHash.config) && (
            <>
              <div hidden={autoColumns}>
                <h3>Columns (so you can copy paste)</h3>
                <ul>
                  {data && data.length > 0 && Object.keys(data[0]).map((k) => <li key={k}>{k}</li>)}
                </ul>
              </div>
              <h3>Create vs Update</h3>
              <span>For the "mode", select "Create" if you want to create new applicants from this data, or use "Update" if you want to change data for existing applciants</span>
              <div className="mt-4 bg-white shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                <Suspense>
                  <Distro
                    ref={distroRef}
                    value={config}
                    types='src/survey.ts'
                    name='CSVMapping'
                    onChange={(v) => {
                      setConfig(v);
                      if (!refsLoaded.current && data && data.length > 0) {
                        refsLoaded.current = true;
                        distroRef.current?.initialize(v, undefined, undefined, undefined, {
                          'csvColumns': Object.keys(data[0])
                        });
                      }
                    }} />
                </Suspense>
              </div>
              <div className="mt-3 text-red-500">{invalidMessage}</div>
              <button
                onClick={() => setPreviewing(true)}
                type="button"
                className={`${invalidMessage ? "opacity-50 cursor-not-allowed" : ""} ${BUTTON_CLASS}`}
                disabled={importing || invalidMessage.length > 0}
              >
                Preview Import
              </button>
            </>
          )}
          {(configContext.roles || []).includes('admin') &&
                <div>
                  <hr className="mt-8" />
                  <h3>Give additional import privileges</h3>
                  <div>Use this tool to create an import link for non-admins to use. Using this link,
                    users with the 'can_import' role will be able to use this page to import values from CSVs, limited
                    only to the provided allowed fields as columns.
                    The allowed fields should be added as comma separated values.
                  </div>
                  <div className="mt-4 mb-4 inline-flex">
                    <span className="font-semibold mr-2">Allowed Fields:</span>
                    <textarea
                      className="px-1 w-[500px]"
                      placeholder='phone_number, email, ...'
                      onChange={e => setAllowedFields(e.target.value)}></textarea>
                  </div>
                  <div>
                    <button
                      type="button"
                      className={BUTTON_CLASS}
                      onClick={async () => {
                        const import_signature = await createImportSignature({
                          allowedFields
                        });
                        const url = new URL(window.location.href);
                        url.searchParams.set('import_signature', import_signature);
                        setGeneratedImportUrl(url.href);
                      }}
                    >
                      Generate link
                    </button>
                  </div>
                  {generatedImportUrl &&
                        <div className="mt-4 mr-4">
                          <span className="font-semibold">Import URL: </span>
                          <span style={{ overflowWrap: 'break-word' }}>{generatedImportUrl}</span>
                        </div>}
                </div>
          }
        </div>}
    </div>
}
