import { Dialog, Transition } from "@headlessui/react";
import { CheckBadgeIcon } from "@heroicons/react/24/solid";
import { ChatBubbleLeftEllipsisIcon, EnvelopeIcon, MagnifyingGlassCircleIcon } from '@heroicons/react/24/outline';
import type { MessageKindWithLink } from "aidkit/lib/application/communicate";
import { Fragment, useContext, useEffect, useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useToast } from "@aidkitorg/component-library";
import { usePost } from "../API";
import InterfaceContext, { ConfigurationContext, PublicConfigurationContext, SupportedLanguage, UserInfoContext } from "../Context";
import { langToWord, useLocalizedStrings } from "../Localization";
import { SpacedSpinner, snakeToEnglish } from "../Util";
import { ClickableButton } from "./Button";
import { DateInput } from "./DateInput";
import { Dropdown } from "./Dropdown";
import { TextInput } from "./TextInput";
import { RichText } from "@aidkitorg/types/lib/survey";

function AdminSearchForm(props: { closeModal: () => void } ) {

  const searchInputRef = useRef(null as any);
  const L = useLocalizedStrings();

  // These fields are searchable by admin to return N results
  const adminSearch = {
    "phone_number": "Phone",
    "email": "Email",
    "screener": "Screener",
    "legal_name": "Full Legal Name"
  }

  const history = useHistory();
  const context = useContext(InterfaceContext);

  const [term, setTerm] = useState("");
  const [field, setField] = useState("phone_number" as keyof typeof adminSearch);
  const search = usePost("/search");
  const [searching, setSearching] = useState(false);
  const [data, setData] = useState([] as any[]);
    
  const getExposedSubsurveys = usePost("/applicant/get_exposed_subsurveys");
  const [gettingSubsurveys, setGettingSubsurveys] = useState(false);
  const [subsurveys, setSubsurveys] = useState([] as any[]);
  const [subsurveysApplicant, setSubsurveysApplicant] = useState("");
  const subsurveyLinks = useSubsurveyLinks({ uid: subsurveysApplicant, subsurveys });

  const doSearch = async () => {
    if (searching) return;
    setSearching(true);
    let result;
    try {
      result = await search({field: field, term});
    } catch (e) {
      console.warn("Search failed", e);
    } finally {
      setData(result?.data || []);
      setSearching(false);
    }
  }

  const doGetExposedSubsurveys = async (applicant: string) => {
    setGettingSubsurveys(true);
    let result = await getExposedSubsurveys({ applicant });
    setSubsurveysApplicant(applicant);
    setGettingSubsurveys(false);

    if (result && result.error) {
      console.warn("Failed to get subsurveys", result.error);
      return;
    }
    console.log("Setting subsurveys: ", result?.subsurveys);
    setSubsurveys(result?.subsurveys || []);
  }
    

  useEffect(() => {
    console.log("Search Ref: ", searchInputRef.current);
    if (searchInputRef.current) searchInputRef.current.focus();
  }, [searchInputRef.current])

  return <>
    <h2 className="text-gray-800 font-medium">{L.applicant_lookup.admin_search}</h2>
    <div className="mt-2 flex flex-row space-x-2">
      <div className="">
        <Dropdown 
          label={adminSearch[field]}
          options={Object.keys(adminSearch).map((f) => ({
            label: adminSearch[f as keyof typeof adminSearch],
            callback: () => setField(f as keyof typeof adminSearch)
          }))}
          direction="right"
        />
      </div>
      <input className="flex grow" value={term} 
        onChange={(e) => setTerm(e.target.value)}
        onKeyUp={(e) => { if (e.key === "Enter") doSearch(); }}
        ref={searchInputRef} />

      <ClickableButton color="blue" disabled={searching}
        onClick={() => { doSearch() }}>
        {searching && <SpacedSpinner />}{L.dashboard.search}
      </ClickableButton>
    </div>

    <div className="mt-4">
      {searching && <div>Searching...</div>}
      {!searching && (
        <>
          {data.length === 0 && <div>No results found</div>}
          {data && data.length > 0 && (
            <table className="table-auto w-full">
              <thead>
                <tr key="search-head">
                  <th key="th1" className="pb-1 border border-top-0  border-left-0 border-right-0 border-bottom-2 border-gray-600">Name</th>
                  <th key="th2" className="pb-1 border border-top-0  border-left-0 border-right-0 border-bottom-2 border-gray-600">Stage</th>
                  <th key="th3" className="pb-1 border border-top-0  border-left-0 border-right-0 border-bottom-2 border-gray-600">Screener</th>
                  <th key="th4" className={`pb-1 border border-top-0 border-left-0 border-right-0 border-bottom-2 border-gray-600`}>...</th>
                </tr>
              </thead>
              <tbody>

                {data.map(d => {
                  // display each row
                  return (
                    <tr key={d.applicant} className="pt-2 my-1">
                      <td key={`${d.applicant}-name`} className="py-2"><Link to={`/applicant/${d.applicant}`} onClick={() => props.closeModal()}>{d.name}</Link></td>
                      <td key={`${d.applicant}-stage`} className="py-2">{d.stage}</td>
                      <td key={`${d.applicant}-screener`} className="py-2">{d.screener}</td>
                      <td key={`${d.applicant}-action`} className={`py-2`}>
                        <ClickableButton color="indigo" 
                          disabled={gettingSubsurveys}
                          onClick={() => doGetExposedSubsurveys(d.applicant)}>
                          {gettingSubsurveys && <SpacedSpinner />}Get Subsurveys
                        </ClickableButton>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
          {(!gettingSubsurveys && !!subsurveys.length) && 
                    <>{subsurveyLinks}</>
          }
        </>
      )}
    </div>
  </>
}

function useSubsurveyLinks(props: { 
  uid: string,
  subsurveys: { 
    subsurveyNameTranslations: Record<string, string>, 
    part: string, 
    url?: string,
    send_link?: RichText,
  }[] 
}) {

  const context = useContext(InterfaceContext);
  const config = useContext(ConfigurationContext);
  const publicConfig = useContext(PublicConfigurationContext);
  const { toast } = useToast();
  
  const [sending, setSending] = useState(false);

  const names = new Intl.DisplayNames([context.lang], { type: "language" });

  const sendExposedSubsurvey = usePost('/applicant/send_secure_subsurvey');

  const sendLinkInLanguage = async (part: string, lang: string, kind: MessageKindWithLink) => {
    setSending(true)
    const result = await sendExposedSubsurvey({ part, lang: lang as SupportedLanguage, kind, applicant: props.uid });
    setSending(false);
    if (result && (result as any).status) {
      toast({
        description: JSON.stringify(result),
        variant: 'success'
      });
    }
  }
    
  // Color the subsurvey based on its first letter
  const color = (part: string) => {
    const colors = ["bg-red-100", "bg-orange-100", "bg-yellow-100", "bg-green-100", "bg-blue-100", "bg-indigo-100", "bg-purple-100"];
    const index = part.charCodeAt(0) % colors.length;
    return colors[index];
  }

  if (!props.subsurveys.length) return <></>

  return <>
    <div className="border border-gray-200 bg-gray-100 rounded p-3 flow-root">
      <h3 className="text-lg font-medium leading-6 text-gray-900">Subsurveys</h3>
      <p className="mt-2 max-w-4xl text-sm text-gray-500">
        The following subsurveys are available for sending or viewing.
      </p>
      {sending && <>
        <SpacedSpinner />Sending...</>}
      {!sending && <ul role="list" className="">
        {props.subsurveys.map((subsurvey, i) => {
          return <li key={subsurvey.part} className={`p-2 border border-left-0 border-right-0 border-gray-300
                        ${i !== 0 ? 'border-top-0' : ''}`}>
            <div className="flex items-center space-x-4">
              <div className="min-w-10 flex-1">
                <p className="truncate text-sm font-medium text-gray-900 my-0">{subsurvey.subsurveyNameTranslations[context.lang]}</p>
                <p className="truncate text-sm text-gray-500 my-0">{subsurvey.part}</p>
              </div>
              <div className="flex flex-1 items-center">
                {subsurvey.send_link && (
                  <div className="p-2">
                    <Dropdown 
                      direction="left"
                      label={<><ChatBubbleLeftEllipsisIcon className="h-5"/>&nbsp;Send via SMS</>}
                      options={
                        (config.languages as string || 'en').split(',').map(lang => {
                          return {
                            label: "Send SMS in " + names.of(lang.replace('_', '-')) + '',
                            callback: async () => {
                              sendLinkInLanguage(subsurvey.part, lang, 'sms');
                            }
                          }
                        })
                      } />&nbsp;
                    {!!publicConfig.comms?.twilioConfig?.whatsapp_messaging_service_sid && <><Dropdown 
                      direction="left"
                      label={<><ChatBubbleLeftEllipsisIcon className="h-5"/>&nbsp;Send via WhatsApp</>}
                      options={
                        (config.languages as string || 'en').split(',').map(lang => {
                          return {
                            label: "Send WhatsApp message in " + names.of(lang.replace('_', '-')) + '',
                            callback: async () => {
                              sendLinkInLanguage(subsurvey.part, lang, 'whatsapp');
                            }
                          }
                        })
                      } />&nbsp;</>}
                    <Dropdown direction="left" label={<><EnvelopeIcon className="h-5"/>&nbsp;Send via Email</>} 
                      options={
                        (config.languages as string || 'en').split(',').map(lang => {
                          return {
                            label: "Send Email in " + names.of(lang.replace('_', '-')) + '',
                            callback: async () => {
                              sendLinkInLanguage(subsurvey.part, lang, 'email');
                            }
                          }
                        })
                      }
                    />
                  </div>
                )}
                {subsurvey.url && <a href={subsurvey.url} className="pl-2">View</a>}
              </div>
            </div>
          </li>
        })}
      </ul>}
    </div>
  </>
}

export function FoundApplicant(props: {
  verifiedSubsurveys: { 
    subsurveyNameTranslations: Record<string, string>, 
    part: string, 
    url?: string,
    send_link?: RichText,
  }[],
  uid: string,
  displayInfo: Record<string, string>
}) {
  const L = useLocalizedStrings();
  const subsurveyLinks = useSubsurveyLinks({ uid: props.uid, subsurveys: props.verifiedSubsurveys });

  return <div className="mb-4">
    <hr />
    <div className="m-2">
      {Object.keys(props.displayInfo).length ?
        <div><strong>{L.applicant_lookup.applicant_info}</strong></div> : <></>
      }
      {Object.keys(props.displayInfo).map(k => <div className='text-sm'>
        <strong>{snakeToEnglish(k)}:</strong> {props.displayInfo[k]}
      </div>)}
    </div>
    {props.verifiedSubsurveys.length > 0 && <>{subsurveyLinks}</>}
    {props.verifiedSubsurveys.length === 0 ? <p>{L.applicant_lookup.no_subsurveys_found}</p> : <></>}
  </div>
}

export function ProtectedSearchForm(props: { closeModal: () => void }) {

  const context = useContext(InterfaceContext);
  const L = useLocalizedStrings();

  const protectedSearch = [{
    'birth_date': L.applicant_lookup.date_of_birth
  }, {
    'phone_number': L.submission.phone_number,
    'email': L.applicant_lookup.email
  }];

  const questions = {
    'street_number': { 
      'en': { 'question': `What is the street number of the applicant's home address?`, 'helptext': "Enter 'unhoused' if they do not have a home address or are currently unhoused." },
      'es': { 'question': '¿Cuál es el número de la calle de su domicilio?', 'helptext': "Ingrese 'unhoused' si está actualmente enfrente de la pobreza." },
      'zh_CN': { 'question': '你的住宅地址的街道号码是什么？', 'helptext': '如果你目前遭遇贫困，请输入“unhoused”。' },
      'yue': { 'question': '你的住宅地址的街道号码是什么？', 'helptext': '如果你目前遭遇贫困，请输入“unhoused”。' },
      'ar': { 'question': 'ما هي عنوان الشارع الخاص بك؟', 'helptext': 'إذا كنت تواجه الحالة المستعجلة، يرجى إدخال "unhoused" ' },
      'pl': { 'question': 'Jakie jest numer domu Twojej domówki?', 'helptext': 'Jeśli obecnie przeżywasz w wolności, wpisz "unhoused".' },
      'tl': { 'question': 'Ano ang iyong address?', 'helptext': "Ilagay ang 'unhoused' kung kasalukuyan kang nakakaranas ng kawalan ng tirahan." }
    }
  }

    type ProtectedSearchField = keyof typeof protectedSearch[number];
    type TwoSearchFields = [ProtectedSearchField, ProtectedSearchField];

    const [terms, setTerms] = useState({} as Record<ProtectedSearchField, string>); // holds our search terms.    
    const [fields, setFields] = useState(['birth_date', 'phone_number'] as TwoSearchFields); // holds the fields we're searching on.
    
    const [searching, setSearching] = useState(false);

    const searchBegin = usePost('/protected_search');
    const searchComplete = usePost('/protected_search_answer');
    
    type SearchResult = Required<Awaited<ReturnType<typeof searchBegin>>>['result'];
    type Question = SearchResult['question'];

    const [searchResult, setSearchResult] = useState(undefined as undefined | SearchResult );
    const [language, setQuestionLanguage] = useState("en" as keyof typeof questions[Question]); // What language to ask the question in
    const [answer, setAnswer] = useState("");

    const [submitting, setSubmitting] = useState(false);
    const [foundApplicants, setFoundApplicants] = useState([] as Required<Awaited<ReturnType<typeof searchComplete>>>['results']);
    const [verified, setVerified] = useState(false);

    const doSearch = async () => {
      console.log(terms, fields);

      setVerified(false);
      setSearching(true);
      const beginResult = await searchBegin({ terms, fields });
      if (beginResult.result) {
        console.log(beginResult.result);
        setSearchResult(beginResult.result);
      }
      setSearching(false);
    }

    const answerSearch = async () => {
      console.log(answer);
      if (!searchResult) return;

      setSubmitting(true);
      const answerResult = await searchComplete({ questionTokens: searchResult.tokens, answer });
      if (answerResult && answerResult.results) {
        console.log(answerResult.results);
        setFoundApplicants(answerResult.results);
        setVerified(true);
      }
      setSubmitting(false);
    }

    return <>
      <h2 className="text-gray-800 font-bold">{L.applicant_lookup.title}</h2>
      {(!searchResult || !searchResult.tokens) && (<p className="text-gray-500 font-light">{L.applicant_lookup.tool_description}</p>)}
      <hr/>
      {(!searchResult || !searchResult['tokens']) && (
        <>
          {protectedSearch.map((allowableFields, i) => {
            return <div key={i} className="mt-2">
              {fields[i] === "birth_date" ? <DateInput key="birth_date" label={allowableFields[fields[i]] || 'Birth Date'} 
                setValue={(v) => {
                  setTerms((prevState) => {
                    const newState = {...prevState};
                    newState[fields[i]] = v;
                    return newState;
                  })
                }} /> : <>
                <div className="my-2">
                  <Dropdown 
                    label={allowableFields[fields[i]]} // this is a bit of a mindfuck
                    options={Object.keys(allowableFields).map((f) => ({
                      label: allowableFields[f as keyof typeof protectedSearch[number]] || '',
                      callback: () => setFields((prevState: TwoSearchFields) => {
                        const newState = [...prevState] as TwoSearchFields;
                        newState[i] = f as keyof typeof protectedSearch[number];
                        return newState;
                      })
                    }))}
                    direction="right"
                  />
                </div>
                <TextInput value={terms[fields[i]]} 
                  label={allowableFields[fields[i]] || 'Search Term'}
                  onChange={(e) => { 
                    let sanitized = e.target.value || '';
                    if (fields[i] === 'phone_number') {
                      // Sanitize
                      sanitized = sanitized.replace(/[^0-9]|^1/g, '');
                      if (sanitized.length > 10) sanitized = sanitized.slice(0, 10);
                    }
                    setTerms((prevState) => {
                      const newState = {...prevState};
                      newState[fields[i]] = sanitized;
                      return newState;
                    })
                  }} /> </> }
              <hr/>
            </div>
          })}
          <div className="mt-4 flex-row w-full flex-end">
            <ClickableButton color="blue" onClick={() => {
              doSearch()
            }}>{searching && <SpacedSpinner />}{L.dashboard.search}</ClickableButton>
          </div>
        </>)}
      {!verified && searchResult && searchResult['tokens'] && (
        <>
          <div className="my-2">
            <Dropdown label={langToWord(language, 'Name')} 
              direction="right"
              options={Object.keys(questions[searchResult.question]).map((l) => {
                return {
                  label: l,
                  callback: () => setQuestionLanguage(l as keyof typeof questions[Question])
                }
              })} />
          </div>
          <TextInput value={answer} 
            label={questions['street_number'][language]['question'] || questions['street_number']['en']['question']}
            helptext={questions['street_number'][language]['helptext'] || questions['street_number']['en']['helptext']}
            onChange={(e) => { 
              setAnswer(e.target.value);
            }} />
          <div className="mt-4 flex-row w-full flex-end">
            <ClickableButton color="green" onClick={() => {
              answerSearch()
            }}>{submitting && <SpacedSpinner />}{L.submission.submit}</ClickableButton>
          </div>
        </>
      )}
      {verified && <div className="mt-4 flex flex-row">
        <CheckBadgeIcon className="h-7 text-blue-500 font-medium my-auto" />&nbsp;<span className="text-gray-800 font-bold">
          {foundApplicants.length > 1 ? L.applicant_lookup.verified_applicants : L.applicant_lookup.verified_applicant}
        </span>
      </div>}
      {verified && 
            foundApplicants.map(a => <FoundApplicant uid={a.uid} verifiedSubsurveys={a.subsurveys} displayInfo={a.displayInfo} />)
      }
    </>
}

function SearchModal(props: { open: boolean, close: () => void }) {
  const searchInputRef = useRef(null)
  const L = useLocalizedStrings();
  const config = useContext(ConfigurationContext);
  const user = useContext(UserInfoContext);

  const isAdmin = (config.roles || '').includes('admin') || user.tags?.includes('admin');

  const [searchForm, setSearchForm] = useState('protected' as 'protected' | 'admin');

  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 overflow-y-auto" 
        style={{ zIndex: 20000 }}
        initialFocus={searchInputRef} onClose={props.close}>
        <div className="flex items-start justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div style={{ minHeight: '550px' }} 
              className="relative md:w-1/2 w-full h-full overflow-y-auto inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left shadow-xl transform transition-all">
              <div>
                <div className="sm:hidden">
                  <label htmlFor="tabs" className="sr-only">Select a search type</label>
                  <select id="tabs" name="tabs" 
                    className="block w-full focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md">
                    <option selected>{L.applicant_lookup.protected_search}</option>
                    <option>{L.applicant_lookup.admin_search}</option>
                  </select>
                </div>
                <div className="hidden sm:block">
                  <nav className="flex space-x-4" aria-label="Tabs">
                    {/* Current: "bg-indigo-100 text-indigo-700", Default: "text-gray-500 hover:text-gray-700" */}
                    <button type="button" onClick={() => setSearchForm("protected")} aria-current="page" 
                      className={`border-0 ${searchForm === 'protected' ? 'bg-indigo-100 text-indigo-700' : 'border-gray-100 bg-white text-gray-400 hover:text-gray-700'} 
                                            px-3 py-2 font-medium text-sm rounded-md`}>{L.applicant_lookup.protected_search}</button>
                    {isAdmin ? <button type="button" onClick={() => setSearchForm("admin")} 
                      className={`border-0 ${searchForm === 'admin' ? 'bg-indigo-100 text-indigo-700' : 'border-gray-100 bg-white text-gray-400 hover:text-gray-700'}
                                         px-3 py-2 drop-shadow font-medium text-sm rounded-md`}>{L.applicant_lookup.admin_search}</button> : <></>}
                  </nav>
                </div>
              </div>
              <div className="mt-4">
                {searchForm === 'protected' && <ProtectedSearchForm closeModal={props.close} />}
                {searchForm === 'admin' && <AdminSearchForm closeModal={props.close} />}
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

/** Search Bar! 
 * Appends to the main NavBar for reviewers and screeners
 * You can search by //, phone number, email (exact values only).
 * Pulls up all applicants matching the criteria.
 * All in a mobile friendly container.
 */
export function SearchBar(props: {
  newInterface?: boolean
}) {
  const [showInput, setShowInput] = useState(false);

  return (
    <>
      {props.newInterface ? 
        <div 
          className={'border-gray-200 text-gray-600 hover:text-indigo-600 mt-3'}>
          <a
            className='ml-1 pl-2 text-gray-600 hover:text-indigo-600 font-medium cursor-pointer flex flex-row'
            key={'searchApplicants'}
            onClick={() => setShowInput(true)}  >
            <MagnifyingGlassCircleIcon className="mt-1 mr-1 w-5 h5"
              style={{ cursor: 'pointer' }} >
            </MagnifyingGlassCircleIcon>
            Applicant Lookup
          </a> 
        </div> :
        <MagnifyingGlassCircleIcon className='h-8 w-8 mx-1 my-auto'
          style={{ cursor: 'pointer' }}
          onClick={() => setShowInput(true)} >
        </MagnifyingGlassCircleIcon>
      }
      <SearchModal open={showInput} close={() => setShowInput(false)} />
    </>
  );

}
